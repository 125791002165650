import React from 'react'
import { navigate } from 'gatsby'

import Navbar from '../navbar'

// @ts-ignore
import ArrowLeft from 'icons/arrow-left.svg'

import 'styles/components/subpage/subpageWrapper.scss'

type SubpageWrapperType = {
  backgroundImage: string
  page?: string
  children?: React.ReactNode
}

const SubpageWrapper: React.FC<SubpageWrapperType> = ({ backgroundImage, page, children }) => {
  return (
    <div className="subpage-container">
      <div className="background-container">
        <img className="image-background" src={backgroundImage} alt={`background`} />
        <div className="background"></div>
      </div>
      <Navbar subpage={true} currentPage={page || ''} />
      <div className="subpage-wrapper">{children}</div>
      <div className="back-button-container" onClick={() => navigate(-1)}>
        <img className="back-button" src={ArrowLeft} alt="back-button" />
        <p>Back</p>
      </div>
    </div>
  )
}

export default SubpageWrapper
