import React, { useCallback, useEffect, useState } from 'react'
import { Link, navigate } from 'gatsby'

import Cart from './cart'

// @ts-ignore
import basketIcon from 'icons/basket.svg'
// @ts-ignore
import menuIcon from 'icons/menu.svg'
// @ts-ignore
import closeIcon from 'icons/close.svg'

import 'styles/components/navbar.scss'

type SideMenuType = {
  navigation: boolean
  cart: boolean
}

const Navbar: React.FC<{ subpage?: boolean; currentPage?: string }> = ({ subpage, currentPage }) => {
  const [sideMenu, toggleSideMenu] = useState<SideMenuType>({
    navigation: false,
    cart: false,
  })
  const [stickyNavbar, setStickyNavbar] = useState<boolean>(false)

  const scrollFunc = () => {
    if (window.scrollY >= 600) {
      setStickyNavbar(true)
    } else {
      setStickyNavbar(false)
    }
  }

  const updateStickyNavbar = useCallback(() => {
    scrollFunc()
  }, [])

  useEffect(() => {
    if (!subpage) {
      updateStickyNavbar()

      window.addEventListener('scroll', scrollFunc)

      return () => window.removeEventListener('scroll', scrollFunc)
    } else return () => null
  })

  const ToggleMenu = (): void => {
    if (!sideMenu.cart) {
      toggleSideMenu(data => ({
        navigation: !data.navigation,
        cart: false,
      }))
    }
  }

  const ToggleCart = (): void => {
    if (!sideMenu.navigation) {
      toggleSideMenu(data => ({
        navigation: false,
        cart: !data.cart,
      }))
    }
  }

  const ScrollTo = (selector: string) => {
    document.querySelector(`.${selector}`)!.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })

    if (sideMenu.navigation) {
      toggleSideMenu({
        navigation: false,
        cart: false,
      })
    }
  }

  const RenderList = (): JSX.Element => {
    if (subpage) {
      return (
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className={currentPage === 'introduction' ? 'active-page' : ''}>
            <Link to="/introduction">Introduction</Link>
          </li>
          <li className={currentPage === 'products' ? 'active-page' : ''}>
            <Link to="/products">Products</Link>
          </li>
          <li className={currentPage === 'about' ? 'active-page' : ''}>
            <Link to="/about">About</Link>
          </li>
          <li className={currentPage === 'fat-burning' ? 'active-page' : ''}>
            <Link to="/fat-burning">Fat Burning</Link>
          </li>
        </ul>
      )
    } else {
      return (
        <ul>
          <li onClick={() => ScrollTo('introduction')}>Introduction</li>
          <li onClick={() => ScrollTo('products')}>Products</li>
          <li onClick={() => ScrollTo('about')}>About</li>
          <li onClick={() => ScrollTo('graphics')}>Graphics</li>
          <li onClick={() => ScrollTo('fat-burning')}>Fat Burning</li>
          <li onClick={() => ScrollTo('contact')}>Contact</li>
        </ul>
      )
    }
  }
  return (
    <React.Fragment>
      <nav className={`${stickyNavbar ? 'sticky' : ''}`}>
        <div className="nav-container">
          <div className="navbar-section menu-toggle autowidth">
            <img className="menu-icon" src={menuIcon} alt="menu-icon" onClick={ToggleMenu} />
          </div>
          <div className="navbar-section autowidth">
            <p className="title" onClick={() => navigate('/')}>
              Sailor's Food
            </p>
          </div>
          <div className={`navbar-section navigation${sideMenu.navigation ? ' active' : ''}`}>
            <img className="closeIcon" src={closeIcon} alt="close-menu-icon" onClick={ToggleMenu} />
            <RenderList />
          </div>
          <div className="navbar-section autowidth"></div>
          {/* <div className={`navbar-section autowidth basket`}>
                        <img className="basket-icon" src={basketIcon} alt="basket" onClick={ToggleCart} />
                    </div> */}
        </div>
      </nav>
      {/* <Cart isOpened={sideMenu.cart} closeCart={ToggleCart} /> */}
    </React.Fragment>
  )
}

export default Navbar
