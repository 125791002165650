import React from 'react'

import SubpageWrapper from './subpageWrapper'

// @ts-ignore
import background from 'images/header.jpg'

import 'styles/components/subpage/subpageText.scss'

type PageType = 'introduction' | 'products' | 'about' | 'fat-burning'

export type SubpageTextType = {
  page?: PageType
  title?: string
  description?: string
  descriptionImage?: string
  imageText?: string
  imageBottom?: boolean
  children?: React.ReactNode
}

const SubpageText: React.FC<SubpageTextType> = ({ children, page, title, description, descriptionImage, imageText, imageBottom }) => {
  const RenderImage = (): JSX.Element => {
    if (descriptionImage) {
      const className = `description-image-container ${imageBottom ? 'bottom-image' : ''}`

      return (
        <div className={className}>
          <img className="description-image" src={descriptionImage!} alt="descriptionImage" />
          {imageText ? <p className="description-image-text">{imageText}</p> : <React.Fragment />}
        </div>
      )
    }

    return <React.Fragment />
  }

  const RenderSubpageText = (): JSX.Element => {
    if (children === undefined) {
      return (
        <div className="description-container">
          <div className="description-wrapper">
            <p className="description-header">{title ?? 'Failed to Fetch'}</p>
            <RenderImage />
            <p
              className="description-text"
              dangerouslySetInnerHTML={{
                __html: description ?? 'Failed to Fetch',
              }}></p>
          </div>
        </div>
      )
    }

    return <React.Fragment />
  }

  return (
    <SubpageWrapper page={page} backgroundImage={background}>
      <RenderSubpageText />
      {children}
    </SubpageWrapper>
  )
}

export default SubpageText
