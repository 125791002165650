import React from 'react'
import PageHelmet from '../components/pageHelmet'

// Components
import SubpageText from '../components/subpage/subpageText'

// Styles
import 'styles/global.scss'
import axios from 'axios'

type IntroductionPageType = {
  serverData: {
    cmsData: {
      data: {
        attributes: {
          Title: string
          Description: string
          Image: {
            Text: string
            Image: {
              data: {
                attributes: {
                  url: string
                }
              }
            }
          }
        }
      }
    }
  }
}

const IntroductionPage: React.FC<IntroductionPageType> = ({ serverData }) => {
  const { cmsData } = serverData

  return (
    <PageHelmet title="Sailor's Food - Introduction">
      <SubpageText
        page="introduction"
        title={cmsData.data?.attributes.Title}
        description={cmsData.data?.attributes.Description}
        descriptionImage={cmsData.data?.attributes.Image.Image.data != null ? `${process.env.GATSBY_PUBLIC_STRAPI_URL}${cmsData.data?.attributes.Image.Image.data.attributes.url}` : undefined}
        imageText={cmsData.data?.attributes.Image.Text}
        imageBottom={true}
      />
    </PageHelmet>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getServerData(context) {
  const data = await axios.get(`${process.env.GATSBY_STRAPI_URL}/api/header-subpage?populate=deep`)

  return {
    status: data.status,
    props: {
      cmsData: data.data ?? null,
    },
    headers: {},
  }
}

export default IntroductionPage
